import React, { useEffect, useState } from 'react';

const ModalCaptcha = ({ onDismissModal }) => {
  const [errorMessage, setErrorMessage] = useState(''); // To store the error message
  const [captchaValid, setCaptchaValid] = useState(false); // To track captcha validity

  useEffect(() => {
    // Initialize Bootstrap modal using jQuery
    $('#modalCaptcha').modal({
      backdrop: 'static',
      keyboard: false,
    });

    // Show captcha when the modal is fully visible
    $('#modalCaptcha').on('shown.bs.modal', function () {
      getCaptcha('canvas'); // Generate captcha when modal opens
    });

    // Handle modal close event
    $('#modalCaptcha').on('hidden.bs.modal', function () {
      if (!captchaValid) {
        $('#modalCaptcha').modal('show'); // Keep the modal open if captcha is not valid
      } else {
        // Remove backdrop and call dismiss callback
        $('.modal-backdrop').remove(); // Remove the backdrop element
        onDismissModal(false); // Callback for modal close if captcha is valid
      }
    });

    // Cleanup function to remove event listeners
    return () => {
      $('#modalCaptcha').modal('hide');
      $('#modalCaptcha').off('shown.bs.modal'); // Remove the shown event listener
      $('#modalCaptcha').off('hidden.bs.modal'); // Remove the hidden event listener
    };
  }, [captchaValid]);

  
  const getCaptcha = (id) => {
    const canvas = document.getElementById(id);
    const pen = canvas.getContext('2d');

    // Generate a 2-digit numeric CAPTCHA
    let captch = Math.floor(10 + Math.random() * 90).toString(); // Ensure it's a 2-digit number

    pen.font = "30px Helvetica Neue";
    pen.fillStyle = "white";
    pen.fillRect(0, 0, 400, 400); // Clear the canvas
    pen.fillStyle = "#2196f3";

    // Store captcha in sessionStorage
    sessionStorage.setItem('captcha', captch);

    // Split captcha into individual characters and draw on canvas
    captch = captch.split('').join(' '); // Add space between digits
    pen.fillText(captch, 40, 40);
};


  // Function to validate the captcha
  const validateCaptcha = () => {
    const enteredCaptcha = document.getElementById('txtCaptcha').value;
    const storedCaptcha = sessionStorage.getItem('captcha');

    // Check if the length of the entered captcha matches or exceeds the stored captcha
    if (enteredCaptcha.length === storedCaptcha.length) {
      if (enteredCaptcha === storedCaptcha) {
        setErrorMessage(''); // Clear error message
        setCaptchaValid(true); // Set captcha as valid
        $('#modalCaptcha').modal('hide'); // Close the modal if captcha is valid
      } else {
        setErrorMessage('Invalid captcha. Please try again.'); // Set error message for wrong captcha
      }
    } else if (enteredCaptcha.length > storedCaptcha.length) {
      setErrorMessage('Input exceeds captcha length.'); // Error for exceeding length
    } else {
      setErrorMessage(''); // Clear error message if lengths don't match
    }
  };

  return (
    <div className="modal fade modal-responsive" id="modalCaptcha" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* No close button to prevent closing */}
          </div>
          <div className="modal-body text-center">
            <div id="ModalCaptchaHolder">
              <div id="messageCaptchaModel"></div>
              <canvas width="220" height="60" style={{ border: '1px solid #dddddd' }} id="canvas"></canvas>
              <button
                type="button"
                onClick={() => getCaptcha('canvas')}
                className="btn btn-success margin-l15 margin-b55"
                style={{ borderRadius: '100%', fontSize: '18px' }}
              >
                <i className="fa fa-refresh"></i>
              </button>
              <div id="captchaForm" role="form">
                <div className="form-group">
                  <input
                    id="txtCaptcha"
                    name="txtCode"
                    type="text"
                    className="form-control"
                    placeholder="Enter Captcha"
                    onChange={validateCaptcha} // Validate captcha on input change
                  />
                </div>
                {/* Display error message if captcha is invalid */}
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {/* No submit button needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCaptcha;
