

export const getDeviceDetails = async(deviceData) => {

  if(deviceData && (deviceData.isMobile || deviceData.isTablet)){
    return `${deviceData.os} ${deviceData.osVersion}`
  }else{
    return`${deviceData.osName} ${deviceData.osVersion}-${deviceData.browserName}`
  }

}
